@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

@font-face {
    font-family: 'Poppins';
    src: local('Poppins') url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
}

.card-image {
    max-height: auto;
    border-radius: 5px;
}

.card-image:hover {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);

    -webkit-transition: transform 1.01s ease-in-out;
    -moz-transition:transform 1.1s ease-in-out;
    -ms-transition:transform 1.1s ease-in-out;
}
.card {
    max-width: auto;
    height: auto;
    overflow: hidden;
    background-color: transparent;
}

.card-title {
    font-family: 'Poppins';
    font-size: 16pt;
    font-weight:lighter;
    color: rgb(139, 91, 1);
    text-align: center;
    padding-top: 30px;
    background-color: transparent;
}

.card-body {
    width: fit-content;
    font-family: 'Poppins';
    font-size: 13.5pt;
    font-weight: lighter;
    padding-top: 12px;
    background-color: transparent;
}