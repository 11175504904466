@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

@font-face {
    font-family: 'Poppins';
    src: local('Poppins') url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
}

.services {
  background-color:rgb(230, 230, 230);
  padding-bottom: 100px;
}

.title-services {
    background-color: rgb(230, 230, 230);
    font-family: 'Poppins';
    font-size: 36pt;
    font-weight: 200;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 60px;
    text-decoration: underline;
    text-decoration-color: lightblue ;
}
  
.services_list {
    columns: 2;
    font-size:2vw;
    list-style-image: url("../WebsitePictures/checkMark.png");
    font-family: 'Poppins';
    font-weight: bold;
    
}

.services_list li {
    padding-bottom: 30px;
    margin-left: 25%;
    margin-right: 25%;
}

.services_sub_list {
  padding-bottom: 30px;
  padding-top: 30px;
  list-style: circle outside none;
  font-family: 'Poppins';
  font-weight: lighter;
}

.services_list li:hover{
  zoom: 102%;
}

.services_bottom_text {
  font-family: 'Poppins';
  text-align: center;
}

.free_estimates{
  font-family: 'Poppins';
  font-weight: bolder;
  text-align: center;
  padding-bottom: 20px;
}

.founded{
  font-family: 'Poppins';
  text-align: right;
  font-weight: 100;
}

@media screen and (max-width: 700px) {
  .title-services{
    margin-top: 0;
  }
  .services {
    padding-bottom: 10px;
  }
  .services_list{
    font-size: 4vw;
    margin: 0;
    padding: 0;
  }
}