@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

@font-face {
    font-family: 'Poppins';
    src: local('Poppins') url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
}

p {
    font-size: 2.0vw;
    
}

.magazine_holder{
    padding: 20px;
}

.magazine_image {
    width: 30%;
    height: auto;
    margin-left: 80px;
}

.magazine_text {
    font-family: 'Poppins';
    font-weight: lighter;
    width: 50%;
    height: auto;
    text-align: center;
    display: inline-flex;
}

@media screen and (max-width: 700px) {
    .magazine_holder{
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .magazine_image {
        margin: 0;
        padding-top: 0px;
        width: 100%;
        height: auto;
    }
    .magazine_text {
        width: 100%;
        padding-top: 40px;
    }

    p {
        font-size: 4vw;
    }
  }