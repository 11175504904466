@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

@font-face {
    font-family: 'Poppins';
    src: local('Poppins') url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
}

.title {
    font-family: 'Poppins';
    font-weight: lighter;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: lightblue ;
    background-color:rgb(230, 230, 230);
}

.text-section {
    font-family: 'Poppins';
    font-size: 20pt;
    line-height: normal;
    font-weight: lighter;
    width: auto;
    height: auto;
    text-align: center;
    background-color:rgb(230, 230, 230);
    padding: 40px; 
}

.meetButton {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #000;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  overflow: visible;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  margin-bottom: 25px;
}
.meetButton:focus {
  text-decoration: none;
}

.meetButton:hover {
  text-decoration: yellow;
}

.meetButton:active {
  box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
  outline: 0;
}

.meetButton:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media screen and(max-width: 700px) {
 
}