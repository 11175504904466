
html {
  background: url('./WebsitePictures/HeaderPhoto.jpg') no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

:root{
    overflow-y: scroll;
    overflow-x: hidden; 
}

.navbar {
  background: url('./WebsitePictures/HeaderPhoto.jpg') no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.header{
  position: absolute;
  top: 45%;
  left: 50%;
}

.aboutUs{
 margin-bottom: 300px;
}

.services{
  margin-top: 100px;
}

@media screen and (max-width: 700px){
  .aboutUs{
    margin-bottom: 75px;
  }
  .services {
    margin-top: 75px;
  }
  .header{
    position: absolute;
    top: 40%;
    left: 50%;
  }
  .navbar {
    background: url('./WebsitePictures/HeaderPhoto.jpg') no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 650px;
  }
}