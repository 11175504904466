@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

@font-face {
    font-family: 'Poppins';
    src: local('Poppins') url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
}

  .logo {
    max-width: 100%;
    height: auto;
  }

  .text-on-image {
    font-family: 'Poppins';
    font-size: 20pt;
    text-align: center;
    color:ghostwhite;
  }

  @media screen and(max-width: 700px){
    .text-on-image{
      padding-bottom: 0px;
    }
  }


  
 