.caro{
    width: 50%;
    position: relative;
    left: 25%;
    padding-top: 50px;
    padding-bottom: 50px;
}

@media screen and (max-width: 700px) {
    .caro{
        width: 100%;
        left: 0;
    }
}