@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

@font-face {
    font-family: 'Poppins';
    src: local('Poppins') url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
}

.footer {
    background-color: rgb(230, 230, 230);
    padding-bottom: 40px;
}


.address {
    font-family: 'Poppins';
    font-size: 2vw;
    font-weight: lighter;
    text-align: center;
    margin-left: 25%;
    margin-right: 25%;
    width: 50%;
}

.title {
    font-family: 'Poppins';
    font-size: 36pt;
    font-weight: 200;
    text-align: center;
    padding-top: 100px;
    margin-bottom: 60px;
    text-decoration: underline;
    text-decoration-color: lightblue ;
    background-color:rgb(230, 230, 230);
}

.container_for_workers {
    columns: 2;
    font-size:2vw;
    list-style: none;
    font-family: 'Poppins';
    font-weight: light;
}

.worker {
    padding-bottom: 30px;
    margin-left: 25%;
    margin-right: 25%;
}

.info {
  padding-bottom: 30px;
  padding-top: 30px;
  list-style: circle outside none;
  font-family: 'Poppins';
  font-weight: lighter;
}

.info li:hover{
  zoom: 102%;
}

@media screen and (max-width: 700px) {
    .container_for_workers{
        font-size: 4vw;
        Columns: 1;
    }
    .address{
        font-size: 4vw;
        width: 100%;
        margin: 0;
        padding-bottom: 20px;
    }
    .worker{
        padding-bottom: 10px;
    }
  }